import React from "react";
import api from "../services/api";
import styled from "styled-components";
import { FaShoppingCart } from "react-icons/fa";
import formatMoney from "../utils/formatMoney";
import useSWR from "swr";

import Shell from "../components/Shell";
import Loading from "../components/Loading";
import ImageShowcase from "../components/ImageShowcase";
import H3 from "../components/H3";
import Section from "../components/Section";
import LargeCarousel from "../components/LargeCarousel";
import Paragraph from "../components/Paragraph";
import HorizontalOptions from "../components/HorizontalOptions";
import Button from "../components/Button";

function View({ history, match }) {
  const [selectedSize, setSelectedSize] = React.useState(null);
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [descriptionVisibility, setDescriptionVisibility] = React.useState(
    false
  );

  const { data: product } = useSWR(`/products/${match.params.productId}`);
  const { data: otherProducts } = useSWR(`/products`);

  function validateFields() {
    if (!selectedSize) throw new Error("Selecione um tamanho para continuar");

    if (!selectedColor) throw new Error("Selecione uma cor para continuar");
  }

  async function addProductToCart() {
    const data = {
      quantity: product.grid_quantity || 1,
      product_id: product.id,
      product_size_id: selectedSize.id,
      product_color_id: selectedColor.id,
    };

    await api.post(`/cart-items`, data);
  }

  async function handleAddToCart() {
    try {
      validateFields();
    } catch (error) {
      alert(error.message);
      return;
    }

    try {
      await addProductToCart();

      alert("Produto adicionado ao carrinho, continue comprando!");

      history.push(`/`);
    } catch (error) {
      alert("Ocorreu um erro, por favor tente novamente");
    }
  }

  async function handleBuyNow() {
    try {
      validateFields();
    } catch (error) {
      alert(error.message);
      return;
    }

    try {
      await addProductToCart();

      history.push(`/cart`);
    } catch (error) {
      alert("Ocorreu um erro, por favor tente novamente");
    }
  }

  function handleProductSelection(product) {
    history.push(`/products/${product.id}`);
  }

  return (
    <Shell navBar={false} style={{ paddingBottom: 88 }}>
      {product ? (
        <Container>
          <div>
            <ImageShowcase images={product.images} />
          </div>

          <div>
            <H3>{product.name}</H3>
            <H3>{formatMoney(product.price)}</H3>

            {product.from_feira_caruaru && <Badge>Feira de Caruaru</Badge>}

            <Paragraph
              lines={descriptionVisibility || 3}
              onClick={() => setDescriptionVisibility((state) => !state)}
            >
              {product.description}
            </Paragraph>

            {product.sizes && (
              <Row>
                <H3>Tamanho</H3>
                <HorizontalOptions
                  options={product.sizes}
                  value={selectedSize}
                  onChange={setSelectedSize}
                />
              </Row>
            )}

            {product.colors && (
              <Row>
                <H3>Cor</H3>
                <HorizontalOptions
                  options={product.colors}
                  value={selectedColor}
                  onChange={setSelectedColor}
                />
              </Row>
            )}

            <Row>
              <H3>Grade</H3>
              <Paragraph>
                {product.grid}, {product.grid_quantity} produtos
              </Paragraph>
            </Row>
            <Row>
              <H3>Composição</H3>
              <Paragraph>{product.materials}</Paragraph>
            </Row>
            <Row>
              <H3>Referência</H3>
              <Paragraph>{product.reference}</Paragraph>
            </Row>
            <Row>
              <H3>Loja</H3>
              <Paragraph>{product.store.name}</Paragraph>
            </Row>
            <Row>
              <H3>Categoria</H3>
              <Paragraph>{product.category.name}</Paragraph>
            </Row>

            <ActionBar>
              <Button onClick={handleAddToCart} style={{ flex: "2" }}>
                <FaShoppingCart />
              </Button>
              <Button onClick={handleBuyNow} style={{ flex: "3" }} primary>
                Comprar
              </Button>
            </ActionBar>
          </div>
        </Container>
      ) : (
        <Loading />
      )}

      {otherProducts?.length && (
        <Section title="Outros produtos que você vai amar">
          <LargeCarousel
            content={otherProducts}
            onSelect={handleProductSelection}
          />
        </Section>
      )}
    </Shell>
  );
}

const Badge = styled.span`
  padding: 2px 10px;
  border-radius: 4px;
  background-color: #ff5a18;
  color: white;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  padding: 16px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 50%);
    grid-gap: 32px;
    padding: 32px;
  }
`;

const Row = styled.div`
  display: flex;
  margin: -8px 0;

  h3 {
    margin-right: 1em;
  }
`;

const ActionBar = styled.div`
  position: fixed;
  background: linear-gradient(to top, white 85%, transparent);
  left: 0;
  bottom: 0;
  right: 0;
  padding: 12px 8px 8px;
  display: flex;
  z-index: 50;

  @media (min-width: 600px) {
    position: relative;
    padding: 0;
    margin: -8px;
  }
`;

export default View;
