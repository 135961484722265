import React from "react";
import { Container as Grid, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import useSWR from "swr";

import Shell from "../components/Shell";
import Banner from "../components/Banner";
import styled from "styled-components";

function View({ history }) {
  const { data: categories } = useSWR(`categories`);

  return (
    <Shell searchBar columnsLayout>
      <CategoryList>
        {categories?.map((category) => (
          <Link to={`/categories/${category.id}`}>{category.name}</Link>
        ))}
      </CategoryList>

      <CustomGrid>
        <Row>
          <Col sm={12} md={4}>
            <Banner img="/images/banners/COMPRE-02.png" href="/categories/2" />
          </Col>
          <Col sm={12} md={4}>
            <Banner img="/images/banners/COMPRE-03.png" href="/categories/3" />
          </Col>
          <Col sm={12} md={4}>
            <Banner img="/images/banners/COMPRE-04.png" href="/categories/1" />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6}>
            <Banner img="/images/banners/COMPRE-08.png" href="/categories/12" />
          </Col>
          <Col sm={12} md={6}>
            <Banner
              img="/images/banners/compre-09-09.png"
              href="/categories/13"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={4}>
            <Banner
              img="/images/banners/compre-09-11.png"
              href="/categories/5"
            />
          </Col>
          <Col sm={12} md={4}>
            <Banner img="/images/banners/COMPRE-06.png" href="/categories/4" />
          </Col>
          <Col sm={12} md={4}>
            <Banner
              img="/images/banners/compre-09-13.png"
              href="/categories/6"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6}>
            <Banner
              img="/images/banners/compre-09-10.png"
              href="/categories/10"
            />
          </Col>
          <Col sm={12} md={6}>
            <Banner img="/images/banners/14-14.png" href="/categories/11" />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={4}>
            <Banner
              img="/images/banners/COMPRE_Prancheta 1.png"
              href="/categories/9"
            />
          </Col>
          <Col sm={12} md={4}>
            <Banner
              img="/images/banners/compre-09-12.png"
              href="/categories/8"
            />
          </Col>
          <Col sm={12} md={4}>
            <Banner img="/images/banners/COMPRE-05.png" href="/categories/7" />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Banner img="/images/banners/COMPRE-07.png" href="/categories/14" />
          </Col>
        </Row>
      </CustomGrid>
    </Shell>
  );
}

const CustomGrid = styled(Grid)`
  > * {
    margin: 30px 0;
  }
`;

const CategoryList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto hidden;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.contrast};
  text-transform: uppercase;
  padding: 1px;

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
    padding: 10px;
    margin: 0 6px;
    white-space: nowrap;
    border: 2px solid #333348;
    padding: 10px 16px;
    margin: -1px;

    &:hover {
      padding-bottom: 8px;
    }
  }

  @media (max-width: 599px) {
    display: none;
  }
`;

export default View;
