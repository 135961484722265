import React from "react";
import api from "../services/api";
import { Container as Grid, Row, Col } from "react-grid-system";

import Shell from "../components/Shell";
import Section from "../components/Section";
import LinkList from "../components/LinkList";
import LargeProductList from "../components/LargeProductList";

function View({ history, match }) {
  const [stores, setStores] = React.useState([]);
  const [categoryLinks, setCategoryLinks] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const fetchContent = React.useCallback(async () => {
    const rawCategories = await api.get(`/categories`).then((res) => res.data);
    const rawStores = await api.get(`/stores`).then((res) => res.data);

    const categoryId = parseInt(match.params.categoryId, 10);
    const selectedCategory = rawCategories.find(
      (category) => category.id === categoryId
    );
    if (!selectedCategory) return;

    setStores(
      rawStores
        .map((store) => ({
          ...store,
          products: selectedCategory.products.filter(
            (product) => product.store_id === store.id
          ),
        }))
        .filter((store) => store.products.length)
    );
    setCategoryLinks(
      rawCategories.map((category) => ({
        path: `/categories/${category.id}`,
        label: category.name,
      }))
    );
    setSelectedCategory(selectedCategory);
  }, [match.params.categoryId]);

  function handleProductSelection(product) {
    history.push(`/products/${product.id}`);
  }

  React.useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  return (
    <Shell searchBar columnsLayout>
      <Grid fluid style={{ padding: 0 }}>
        <Row gutterWidth={16}>
          <Col sm={12} lg={3}>
            <Section title="Outras categorias">
              <LinkList links={categoryLinks} />
            </Section>
          </Col>

          <Col sm={12} lg={9}>
            <Section title={selectedCategory?.name}>
              <LargeProductList
                stores={stores}
                onSelectProduct={handleProductSelection}
              />
            </Section>
          </Col>
        </Row>
      </Grid>
    </Shell>
  );
}

export default View;
