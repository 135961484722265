import React from "react";
import styled from "styled-components";
import { useFormState } from "react-use-form-state";
import api from "../services/api";
import { saveAuthData } from "../services/auth";

import TextInput from "../components/TextInput";
import FormSubmitButton from "../components/FormSubmitButton";
import Link from "../components/Link";

function View({ history, location }) {
  const [formState, { email, password }] = useFormState();

  function handleSubmit(event) {
    event.preventDefault();

    const data = formState.values;

    api
      .post(`/sessions`, data)
      .then((response) => {
        saveAuthData(response.data);
        history.push(location?.state?.from ?? "/");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("Usuário ou senha incorretos, por favor verifique");
        } else {
          alert("Ocorreu um erro ao fazer login, tente novamente");
        }
      });
  }

  return (
    <Container>
      <Title>Faça login para continuar comprando!</Title>

      <form onSubmit={handleSubmit}>
        <TextInput placeholder="Email" required {...email("email")} />
        <TextInput placeholder="Senha" required {...password("password")} />

        <FormSubmitButton>Entrar</FormSubmitButton>

        <p>
          <Link to="signup">Não tem conta? cadastre-se</Link>
        </p>
        <p>
          <Link>Esqueci a senha</Link>
        </p>
      </form>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: #3a3a3a;
`;

export default View;
