import React from "react";
import styled from "styled-components";

import H3 from "./H3";

function Component({ title, children }) {
  return (
    <Container>
      {title && <H3>{title}</H3>}
      {children}
    </Container>
  );
}

const Container = styled.section`
  margin: 16px auto;
  padding: 16px;
  max-width: 1440px;
`;

export default Component;
