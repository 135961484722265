import React from "react";
import api from "../services/api";
import formatMoney from "../utils/formatMoney";
import styled from "styled-components";
import { FaMinus, FaPlus } from "react-icons/fa";
import useSWR from "swr";
import updateArray from "../utils/updateArray";
import removeArrayItem from "../utils/removeArrayItem";

import Shell from "../components/Shell";
import Button from "../components/Button";
import H3 from "../components/H3";

function View({ history }) {
  const { data: cart, error, mutate: mutateCart } = useSWR(`/cart-items`);

  async function decreaseItemQuantity({ id, ...item }) {
    const quantity = item.quantity - item.product.grid_quantity;

    if (quantity > 0) {
      mutateCart(updateArray(cart, { id, quantity }), false);
      await api.put(`/cart-items/${id}`, { quantity });
      mutateCart();
    } else {
      if (window.confirm("Deseja remover esse produto do seu carrinho?")) {
        mutateCart(removeArrayItem(cart, { id }), false);
        await api.delete(`/cart-items/${id}`);
        mutateCart();
      }
    }
  }

  async function increaseItemQuantity({ id, ...item }) {
    const quantity = item.quantity + item.product.grid_quantity;

    mutateCart(updateArray(cart, { id, quantity }), false);
    await api.put(`/cart-items/${id}`, { quantity });
    mutateCart();
  }

  function handleOrder() {
    history.push(`/cart/address`);
  }

  if (error) {
    return (
      <Shell navBar={false}>
        <H3>Ocorreu um erro ao carregar seus dados, tente novamente</H3>
      </Shell>
    );
  }

  if (!cart) {
    return <Shell navBar={false} />;
  }

  if (cart.length === 0) {
    return (
      <Shell navBar={false}>
        <H3>Seu carrinho está vazio</H3>
      </Shell>
    );
  }

  return (
    <Shell navBar={false}>
      {cart
        ?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
        .map((cartItem) => (
          <Card key={cartItem.id}>
            <Thumbnail src={cartItem.product.thumbnail} />

            <Content>
              <Title>{cartItem.product.name}</Title>
              <Title>
                {cartItem.color.label}, {cartItem.size.label}, grade com{" "}
                {cartItem.product.grid_quantity}
              </Title>
              <Price>{formatMoney(cartItem.product.price)}</Price>
            </Content>

            <ProductActionBar>
              <button onClick={() => decreaseItemQuantity(cartItem)}>
                <FaMinus />
              </button>

              <span>{cartItem.quantity}</span>

              <button onClick={() => increaseItemQuantity(cartItem)}>
                <FaPlus />
              </button>
            </ProductActionBar>
          </Card>
        ))}

      <ActionBar>
        <Button onClick={handleOrder} style={{ flexGrow: 1 }} primary>
          Finalizar compra
        </Button>
      </ActionBar>
    </Shell>
  );
}

const Card = styled.div`
  position: relative;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 16px 8px;
  display: flex;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Thumbnail = styled.img`
  height: 83px;
  width: auto;
`;

const Title = styled.p`
  margin: 0;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: #3a3a3a;
  max-height: 17px;
  overflow: hidden;
`;

const Price = styled.p`
  margin: 0;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  margin-right: 28px;
`;

const ProductActionBar = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 16px 0;
  display: flex;

  > * {
    min-width: 36px;
    height: 36px;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
`;

const ActionBar = styled.div`
  position: fixed;
  background: linear-gradient(to top, white 85%, transparent);
  left: 0;
  bottom: 0;
  right: 0;
  padding: 12px 8px 8px;
  display: flex;
  z-index: 50;

  @media (min-width: 600px) {
    position: relative;
    padding: 0;
    margin: -8px;
  }
`;

export default View;
