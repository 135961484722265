import React from "react";
import styled, { keyframes } from "styled-components";

function Component() {
  return (
    <Container>
      <Spínner />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const spinnerAnimation = keyframes`
0%{
  transform:rotate(0)
}

100%{
  transform:rotate(360deg);
}`;

const Spínner = styled.div`
  width: 5em;
  height: 5em;
  border-radius: 99em;
  border: 0.4em solid;
  border-color: #d9d9d9 #d9d9d9 transparent;
  animation: ${spinnerAnimation} 3s linear infinite;
`;

export default Component;
