import React from "react";
import api from "../services/api";

import Shell from "../components/Shell";
import Loading from "../components/Loading";

function View() {
  const [, setProducts] = React.useState([]);

  function fetchContent() {
    api.get(`profile`).then((response) => {
      setProducts(response.data);
    });
  }

  React.useEffect(fetchContent, []);

  return (
    <Shell title="Configurações">
      <Loading />
    </Shell>
  );
}

export default View;
