import React from "react";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";

function FormSubmitButton({ children }) {
  return (
    <Container>
      <Label>{children}</Label>
      <Button type="submit">
        <FaArrowRight />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -4px;
`;

const Label = styled.span`
  color: #3a3a3a;
  font-weight: bold;
  font-size: 24px;
`;

const Button = styled.button`
  border: none;
  border-radius: 99px;
  width: 80px;
  height: 80px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${(props) => props.theme.colors.secondary};
  margin: 32px 0;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
`;

export default FormSubmitButton;
