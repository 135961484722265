import React from "react";
import Routes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import "./styles/globalStyle.css";
import "@brainhubeu/react-carousel/lib/style.css";
import FloatingContactAgent from "./components/FloatingContactAgent";
import { SWRConfig } from "swr";
import { swrFetcher } from "./services/api";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primary: "#00002A",
    secondary: "#FF5A18",
    accent: "",
    contrast: "#FFFFFF",
  },
};

function App() {
  return (
    <React.StrictMode>
      <SWRConfig value={{ refreshInterval: 60000, fetcher: swrFetcher }}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
            <FloatingContactAgent />
          </BrowserRouter>
        </ThemeProvider>
      </SWRConfig>
    </React.StrictMode>
  );
}

export default App;
