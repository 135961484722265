import React from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

function Component({ onSearch, value, onChange }) {
  function handleSubmit(event) {
    event.preventDefault();

    onSearch(value);
  }

  return (
    <FormContainer onSubmit={handleSubmit} className="searchBar">
      <TextInput
        placeholder="O que você está procurando?"
        type="text"
        value={value}
        onChange={onChange}
        name="query"
      />
      <SubmitButton>
        <FaSearch />
      </SubmitButton>
    </FormContainer>
  );
}

const FormContainer = styled.form`
  position: relative;
  margin: 16px 0;
  width: 100%;
`;

const TextInput = styled.input`
  line-height: 44px;
  padding: 0 44px 0 16px;
  width: 100%;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
`;

const SubmitButton = styled.button`
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.primary};
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
`;

export default Component;
