function updateArray(array, newItem, key = "id", merge = true) {
  return array.map((item) => {
    if (item[key] === newItem[key]) {
      if (merge) {
        return { ...item, ...newItem };
      } else {
        return newItem;
      }
    }

    return item;
  });
}

export default updateArray;
