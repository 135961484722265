import styled from "styled-components";

const Button = styled.button`
  margin: 8px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  line-height: 56px;
  color: ${(props) => (props.primary ? "white" : "#3a3a3a")};
  background-color: ${(props) => (props.primary ? "#e67e22" : "white")};
  font-size: 18px;
  font-weight: 500;
`;

export default Button;
