import React from "react";
import styled from "styled-components";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import { FaShoppingCart, FaWhatsapp, FaPhone, FaUser } from "react-icons/fa";
import logo from "../assets/brand/logo-horizontal-dark.png";

import SearchBar from "./SearchBar";

function Component({ children, searchBar = false }) {
  const [query, setQuery] = React.useState("");
  const history = useHistory();
  const match = useRouteMatch();

  function handleGoToHome() {
    history.push(`/`);
  }

  function handleSearch(query) {
    history.push(`/search/${query}`);
  }

  function fetchQuery() {
    setQuery(match.params.query ?? "");
  }

  function handlePhone(event) {
    event.preventDefault();
    window.open("tel:+558131378896", "_self");
  }

  function handleWhatsapp(event) {
    event.preventDefault();
    window.open(
      "https://api.whatsapp.com/send?phone=558193140230&text=Ol%C3%A1!"
    );
  }

  React.useEffect(fetchQuery, [match.params.query]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Logo src={logo} onClick={handleGoToHome} />

          <SearchBar
            onSearch={handleSearch}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />

          <HeaderActions>
            <HeaderButton to="" onClick={handleWhatsapp}>
              <FaWhatsapp />
            </HeaderButton>

            <HeaderButton to="" onClick={handlePhone}>
              <FaPhone />
            </HeaderButton>

            <HeaderButton to="/profile">
              <FaUser />
            </HeaderButton>

            <HeaderButton to="/cart">
              <FaShoppingCart />
            </HeaderButton>
          </HeaderActions>
        </HeaderContent>
      </Header>

      <Content>{children}</Content>

      <Footer>
        <p>&copy; CompreNosso {new Date().getFullYear()}</p>
        <p>Todos os direitos reservados</p>
        <p>
          <br />
          <a href="/privacy-policy.html" target="_blank">
            Política de privacidade e termos de uso
          </a>
        </p>
      </Footer>
    </Container>
  );
}

const Footer = styled.footer`
  padding: 3em 1em;
  color: ${(props) => props.theme.colors.contrast};
  background-color: ${(props) => props.theme.colors.primary};

  p {
    text-align: center;

    a {
      color: inherit;
    }
  }
`;

const Container = styled.div`
  height: 100vh;
`;

const Header = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.contrast};
`;

const HeaderActions = styled.div`
  display: flex;
`;

const Logo = styled.img`
  max-height: 32px;
  margin: 8px;
  cursor: pointer;

  @media (min-width: 992px) {
    max-height: 64px;
  }
`;

const HeaderContent = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 16px;
  box-sizing: border-box;

  .searchBar {
    max-width: 400px;
  }

  @media (max-width: 599px) {
    .searchBar {
      display: none;
    }
  }

  @media (min-width: 992px) {
    height: 96px;
  }
`;

const HeaderButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border: none;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: none;
  width: 48px;
  height: 48px;

  svg {
    font-size: 24px;
  }
`;

const Content = styled.main`
  padding-top: 64px;

  @media (min-width: 992px) {
    padding-top: 96px;
  }
`;

export default Component;
