import React from "react";
import api from "../services/api";
import styled from "styled-components";
import { Container as Grid, Row, Col } from "react-grid-system";

import Shell from "../components/Shell";
import H3 from "../components/H3";
import H4 from "../components/H4";
import ProductList from "../components/ProductList";
import Select from "../components/Select";
import Section from "../components/Section";
import RadioList from "../components/RadioList";

function View({ history, match }) {
  const [products, setProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [displayOrder, setDisplayOrder] = React.useState("relevance");
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const categoriesOptions = React.useMemo(mapCategoriesToOptions, [categories]);
  const searchQuery = match.params.query;

  function mapCategoriesToOptions() {
    return categories.map((category) => ({
      value: category.id,
      label: category.name,
    }));
  }

  function fetchProducts() {
    const sanitizedQuery = searchQuery.toLowerCase();

    api.get(`/products`).then((response) => {
      const products = response.data.filter((product) => {
        const nameMatches = product.name.toLowerCase().includes(sanitizedQuery);

        if (selectedCategory) {
          return (
            nameMatches &&
            parseInt(selectedCategory, 10) === product.category_id
          );
        } else {
          return nameMatches;
        }
      });

      if (displayOrder === "lower-price") {
        setProducts(products.sort((a, b) => a.price - b.price));
      } else if (displayOrder === "bigger-price") {
        setProducts(products.sort((a, b) => b.price - a.price));
      } else {
        setProducts(products);
      }
    });
  }

  function fetchCategories() {
    api.get(`/categories`).then((response) => {
      setCategories(response.data);
    });
  }

  function handleProductSelection(product) {
    history.push(`/products/${product.id}`);
  }

  React.useEffect(fetchProducts, [searchQuery, displayOrder, selectedCategory]);
  React.useEffect(fetchCategories, []);

  return (
    <Shell navBar={false} searchBar>
      <Grid fluid style={{ padding: 0 }}>
        <Row gutterWidth={16}>
          <Col sm={12} lg={3}>
            <Section title="Opções de Pesquisa">
              <H4>Categoria</H4>
              <RadioList
                name="category-search-select"
                options={categoriesOptions}
                onChange={setSelectedCategory}
              />
            </Section>
          </Col>

          <Col sm={12} lg={9}>
            <TitleBar>
              <H3>
                {products.length
                  ? "Resultados da pesquisa"
                  : "Nenhum produto encontrado"}
              </H3>

              <Select
                value={displayOrder}
                onChange={(event) => setDisplayOrder(event.target.value)}
              >
                <option value="relevance">Mais Relevantes</option>
                <option value="lower-price">Menores Preços</option>
                <option value="bigger-price">Maiores Preços</option>
                <option value="best-sellers">Mais vendidos</option>
              </Select>
            </TitleBar>

            <ProductList content={products} onSelect={handleProductSelection} />
          </Col>
        </Row>
      </Grid>
    </Shell>
  );
}

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export default View;
