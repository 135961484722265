import React from "react";
import styled from "styled-components";
import formatMoney from "../utils/formatMoney";
import { FaPlus } from "react-icons/fa";

function Component({ content = [], onSelect = () => {} }) {
  function handleSelect(item) {
    onSelect(item);
  }

  return (
    <Container>
      {content.map((item) => (
        <Card key={item.id} onClick={(event) => handleSelect(item, event)}>
          <Thumbnail src={item.thumbnail} />

          <Content>
            <Title>{item.name}</Title>
            <Price>{formatMoney(item.price)}</Price>
          </Content>

          <AddToCartButton>
            <FaPlus />
          </AddToCartButton>
        </Card>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-gap: 16px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled.div`
  position: relative;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Thumbnail = styled.img`
  height: 83px;
  width: auto;
`;

const Title = styled.p`
  margin: 0;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: #3a3a3a;
  margin-right: 28px;
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Price = styled.p`
  margin: 0;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  margin-right: 28px;
`;

const AddToCartButton = styled.button`
  position: absolute;
  border: none;
  cursor: pointer;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  font-size: 18px;
  width: 36px;
  height: 36px;
  border-radius: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Component;
