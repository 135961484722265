import React from "react";
import styled from "styled-components";
import { useFormState } from "react-use-form-state";
import api from "../services/api";
import { cpf } from "cpf-cnpj-validator";
import dayjs from "dayjs";
import { saveAuthData } from "../services/auth";

import TextInput from "../components/TextInput";
import FormSubmitButton from "../components/FormSubmitButton";
import Link from "../components/Link";

function View({ history, location }) {
  const [formState, { text, email, password }] = useFormState();

  function handleSubmit(event) {
    event.preventDefault();

    const data = {
      name: formState.values.name,
      cpf: formState.values.cpf,
      birthdate: formState.values.birthdate,
      email: formState.values.email,
      password: formState.values.password,
    };

    if (!cpf.isValid(data.cpf)) {
      alert("CPF Inválido");
      return;
    }
    if (dayjs(data.birthdate).isAfter(Date.now())) {
      alert("Data de nascimento inválida");
      return;
    }
    if (data.password !== formState.values.confirmationPassword) {
      alert("As senhas devem ser iguais");
      return;
    }

    api
      .post(`/users`, data)
      .then((response) => {
        saveAuthData(response.data);
        history.push(location?.state?.from ?? "/");
      })
      .catch(() => {
        alert("Ocorreu um erro ao fazer o cadastro, tente novamente");
      });
  }

  return (
    <Container>
      <Title>Cadastre-se no CompreNosso</Title>

      <form onSubmit={handleSubmit}>
        <TextInput placeholder="Nome" required {...text("name")} />
        <TextInput placeholder="CPF" required {...text("cpf")} />
        <TextInput
          placeholder="Data de nascimento"
          required
          {...text("birthdate")}
          type="date"
        />
        <TextInput placeholder="Email" required {...email("email")} />
        <TextInput placeholder="Senha" required {...password("password")} />
        <TextInput
          placeholder="Confirme a senha"
          required
          {...password("confirmationPassword")}
        />

        <FormSubmitButton>Cadastrar-se</FormSubmitButton>

        <p>
          <Link to="/signin">Já tem conta? Faça login</Link>
        </p>
      </form>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 500px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: #3a3a3a;
`;

export default View;
