import styled from "styled-components";

const Select = styled.select`
  border: 0;
  height: 32px;
  padding: 0 1em;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  cursor: pointer;
  outline: none;
`;

export default Select;
