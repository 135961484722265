import styled from "styled-components";

const H3 = styled.h3`
  color: #3a3a3a;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 16px 0;
`;

export default H3;
