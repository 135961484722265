import React from "react";
import styled from "styled-components";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Carousel from "@brainhubeu/react-carousel";

import LargeProductCard from "./LargeProductCard";

function Component({ content = [], onSelect = () => {} }) {
  function handleSelect(item) {
    onSelect(item);
  }

  return (
    <Container>
      <Carousel
        breakpoints={{
          480: { slidesPerPage: 1.7 },
          768: { slidesPerPage: 2 },
          9999: { slidesPerPage: 3 },
        }}
        dots
        infinite
        arrowLeft={
          <ArrowButton style={{ left: 8 }}>
            <FaAngleLeft />
          </ArrowButton>
        }
        arrowLeftDisabled={<></>}
        arrowRight={
          <ArrowButton style={{ right: 8 }}>
            <FaAngleRight />
          </ArrowButton>
        }
        arrowRightDisabled={<></>}
        addArrowClickHandler
      >
        {content.slice(0, 10).map((item) => (
          <LargeProductCard
            key={item.id}
            onClick={(event) => handleSelect(item, event)}
            name={item.name}
            thumbnail={item.thumbnail}
            price={item.price}
          />
        ))}
      </Carousel>
    </Container>
  );
}

const Container = styled.div`
  .BrainhubCarousel {
    position: relative;
  }

  .BrainhubCarousel__track {
    padding: 0 8px !important;
  }

  .BrainhubCarouselItem {
    align-items: stretch !important;
  }

  .BrainhubCarousel__dot {
    background-color: transparent;
    padding: 4px;

    &::before {
      width: 8px;
      height: 8px;
    }
  }
`;

const ArrowButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  width: 48px;
  height: 48px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.contrast};
  font-size: 32px;
  box-shadow: 0 4px 8px #0002;
  position: absolute;
  z-index: 10;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

export default Component;
