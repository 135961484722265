import React from "react";
import styled from "styled-components";
import formatMoney from "../utils/formatMoney";

function Component({ onClick, name, thumbnail, price }) {
  return (
    <Container onClick={onClick}>
      <Thumbnail src={thumbnail} />
      <Space />

      <Content>
        <Title>{name}</Title>
        <Price>{formatMoney(price)}</Price>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  box-shadow: 0 8px 8px #0002;
  box-sizing: border-box;
  margin: 16px 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Space = styled.div`
  flex: 1;
`;

const Thumbnail = styled.img`
  width: 100%;
`;

const Content = styled.div`
  padding: 16px;
`;

const Title = styled.p`
  margin: 0;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  margin-right: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Price = styled.p`
  margin: 0;
  line-height: 1.4;
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  margin-right: 36px;
`;

export default Component;
