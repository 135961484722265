import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function Component({ classname, img, alt = "", href, label }) {
  return (
    <Container classname={classname}>
      {href ? (
        <Link to={href}>
          <img src={img} alt={alt} />
        </Link>
      ) : (
        <img src={img} alt={alt} />
      )}

      {label && (
        <Overlay>
          <span>{label}</span>
        </Overlay>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;

  a {
    display: block;
  }

  img {
    display: block;
    width: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1066;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;

  span {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 40px;
    font-weight: bold;
  }
`;

export default Component;
