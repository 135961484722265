import React from "react";
import styled, { css } from "styled-components";

function Component({ options = [], value = {}, onChange = () => {} }) {
  return (
    <Container>
      {options.map((option) => (
        <Option
          key={option.id}
          onClick={() => onChange(option)}
          color={option.hex}
          active={option.id === value?.id}
        >
          {!option.hex && option.label}
        </Option>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Option = styled.button`
  border: none;
  border-radius: 12px;
  min-width: 40px;
  height: 40px;
  background-color: transparent;
  margin: 4px;
  padding: 4px 12px;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  font-weight: bold;
  color: #222;

  &::before {
    content: "";
    width: calc(100% - 8px);
    height: 32px;
    background-color: ${(props) => props.color || "#ddd"};
    border-radius: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: -1;
  }

  ${(props) =>
    props.active &&
    css`
      border: 2px solid #777777;
      padding: 2px 10px;

      &::before {
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
      }
    `}
`;

export default Component;
