const USER_KEY = "@CompreNosso-client:user";

export function isAuthenticated() {
  return !!localStorage.getItem(USER_KEY);
}

export function getAuthData() {
  return JSON.parse(localStorage.getItem(USER_KEY));
}

export function saveAuthData(user) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function removeAuthData() {
  localStorage.removeItem(USER_KEY);
}
