import React from "react";
import api from "../services/api";
import styled from "styled-components";
import { useFormState } from "react-use-form-state";
import cepPromise from "cep-promise";

import TextInput from "../components/TextInput";
import Shell from "../components/Shell";
import Button from "../components/Button";
import H3 from "../components/H3";

function View() {
  const [formState, { text }] = useFormState();

  async function handleOrder(event) {
    event.preventDefault();

    const cepRegex = /^\d{5}-?\d{3}$/;

    if (!cepRegex.test(formState.values.postal_code)) {
      alert("Digite um CEP Válido!");
      return;
    }

    const data = formState.values;

    await api.post(`/user-addresses`, data);

    setTimeout(() => {
      alert("Ocorreu um erro ao processar o pagamento, tente novamente");
    }, 3000);
  }

  function fetchAddressByCep() {
    const cep = formState?.values?.postal_code?.replace(/-/g, "");

    if (cep.length === 8) {
      cepPromise(cep).then((data) => {
        formState.setField("city", data.city);
        formState.setField("state", data.state);
        formState.setField("neighborhood", data.neighborhood);
        formState.setField("street", data.street);
      });
    }
  }

  React.useEffect(fetchAddressByCep, [formState?.values?.postal_code]);

  return (
    <Shell navBar={false}>
      <H3>Preencha seu endereço para finalizar a compra</H3>

      <form onSubmit={handleOrder}>
        <TextInput placeholder="CEP" required {...text("postal_code")} />
        <TextInput placeholder="Cidade" required {...text("city")} />
        <TextInput placeholder="Estado" required {...text("state")} />
        <TextInput placeholder="Bairro" required {...text("neighborhood")} />
        <TextInput placeholder="Rua" required {...text("street")} />
        <TextInput placeholder="Número" required {...text("number")} />

        <ActionBar>
          <Button style={{ flexGrow: 1 }} primary>
            Continuar para pagamento
          </Button>
        </ActionBar>
      </form>
    </Shell>
  );
}

const ActionBar = styled.div`
  position: fixed;
  background: linear-gradient(to top, white 85%, transparent);
  left: 0;
  bottom: 0;
  right: 0;
  padding: 12px 8px 8px;
  display: flex;
  z-index: 50;

  @media (min-width: 600px) {
    position: relative;
    padding: 0;
    margin: -8px;
  }
`;

export default View;
