import React from "react";
import styled, { css } from "styled-components";

function Component({ placeholder, value, onChange, type, ...props }) {
  const [focused, setFocused] = React.useState(false);

  const shouldLabelHide = focused || value || type === "date";

  return (
    <Container>
      <Label hide={shouldLabelHide}>{placeholder}</Label>
      <Input
        {...props}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  margin: 32px 0 16px;
`;

const Label = styled.span`
  color: #3a3a3a;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 54px;
  pointer-events: none;
  transition: all 0.2s ease;
  transform-origin: left;

  ${(props) =>
    props.hide &&
    css`
      transform: translateY(-26px) scale(0.75);
    `}
`;

const Input = styled.input`
  line-height: 54px;
  padding: 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #3a3a3a;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  color: #3a3a3a;
  background-color: transparent;
`;

export default Component;
