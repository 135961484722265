import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function Component({ links = [] }) {
  return (
    <Container>
      {links.map((link) => (
        <Link key={link.path} to={link.path}>
          {link.label}
        </Link>
      ))}
    </Container>
  );
}

const Container = styled.div``;

const Link = styled(NavLink)`
  color: #3a3a3a;
  display: block;
  line-height: 32px;
  text-decoration: none;
  padding: 8px 12px 8px 8px;
  margin-left: -8px;

  &.active {
    padding-left: 8px;
    border-left: 4px solid #e67e22;
    margin-left: -12px;
  }

  &:hover {
    background-color: #eee;
  }
`;

export default Component;
