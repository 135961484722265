const numberFormat = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

function formatMoney(value) {
  return numberFormat.format(value / 100);
}

export default formatMoney;
