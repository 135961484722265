import React from "react";
import styled from "styled-components";
import Carousel, { Dots } from "@brainhubeu/react-carousel";

function Component({ images = [] }) {
  const [currentImage, setCurrentImage] = React.useState(0);

  const thumbnails = images.map((image) => <Thumbnail src={image?.src} />);

  const slides = images.map((image) => <BigImage src={image?.src} />);

  return (
    <Container>
      <Carousel
        slidesPerPage={1}
        value={currentImage}
        slides={slides}
        onChange={setCurrentImage}
      />
      <Dots
        number={thumbnails.length}
        thumbnails={thumbnails}
        value={currentImage}
        onChange={setCurrentImage}
      />
    </Container>
  );
}

const Container = styled.div``;

const BigImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Thumbnail = styled.img`
  max-width: 32px;
  max-height: 32px;
  margin: 0 8px;
`;

export default Component;
