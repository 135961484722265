import React from "react";
import styled, { css } from "styled-components";
import { FaComment, FaTimes, FaPhone, FaWhatsapp } from "react-icons/fa";

import H3 from "../components/H3";

function Component() {
  const [visibility, setVisibility] = React.useState(false);

  function triggerFirstOpening() {
    setTimeout(() => {
      setVisibility(true);
    }, 10000);
  }

  React.useEffect(triggerFirstOpening, []);

  return (
    <Container>
      <Card visible={visibility}>
        <CloseButton onClick={() => setVisibility(false)}>
          <FaTimes />
        </CloseButton>
        <H3>Se precisar de ajuda, estamos aqui para ajudar!</H3>
        <LinkButton href="tel:+558131378896">
          <FaPhone /> (81) 3137-8896
        </LinkButton>
        <LinkButton href="https://api.whatsapp.com/send?phone=558189645097&text=Ol%C3%A1!">
          <FaWhatsapp /> (81) 98964-5097
        </LinkButton>
      </Card>

      <FloatingButton onClick={() => setVisibility((state) => !state)}>
        <FaComment />
      </FloatingButton>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  display: flex;
  align-items: flex-end;

  @media (max-width: 599px) {
    display: none;
  }
`;

const Card = styled.div`
  background-color: white;
  padding: 1em 1.5em;
  border-radius: 8px;
  box-shadow: 0 8px 16px #0005;
  margin-right: 16px;
  transform: translateX(150%);
  transition: all 0.5s ease;

  h3 {
    margin-top: 0;
  }

  ${(props) =>
    props.visible &&
    css`
      transform: translateX(0);
    `}
`;

const CloseButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  font-size: 12px;
  color: #3a3a3a;
  background-color: white;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  top: -9px;
  right: -6px;
`;

const LinkButton = styled.a`
  color: white;
  background-color: ${(props) => props.theme.colors.secondary};
  text-decoration: none;
  padding: 0.2em 0.6em;
  border-radius: 4px;
  margin-right: 8px;

  svg {
    margin-bottom: -2px;
  }
`;

const FloatingButton = styled.button`
  width: 64px;
  height: 64px;
  cursor: pointer;
  border: none;
  border-radius: 999px;
  box-shadow: 0 8px 16px #0005;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  outline: none;
  z-index: 110;
`;

export default Component;
