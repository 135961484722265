import React from "react";
import styled from "styled-components";

function Component({ name, options = [], onChange }) {
  function handleChange(event) {
    onChange(event.target.value);
  }

  return (
    <Container>
      {options.map((option) => (
        <Label key={option.value}>
          <Input
            type="radio"
            name={name}
            value={option.value}
            onChange={handleChange}
          />
          <CheckMark />
          <span>{option.label}</span>
        </Label>
      ))}
    </Container>
  );
}

const Container = styled.form``;

const Label = styled.label`
  display: flex;
  align-items: flex-end;
  color: #3a3a3a;
  line-height: 30px;
  padding: 8px 12px;
  margin-left: -12px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

const Input = styled.input`
  display: none;
`;

const CheckMark = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 6px;
  border: 2px solid #666;
  margin: 6px 8px 6px 0;
  position: relative;

  input:checked ~ &::after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: #666;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 2px;
  }
`;

export default Component;
