import styled from "styled-components";

const H4 = styled.h4`
  color: #3a3a3a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 8px 0;
`;

export default H4;
