import styled, { css } from "styled-components";

const Paragraph = styled.p`
  color: #3a3a3a;
  font-size: 16px;
  line-height: 1.618;

  ${(props) =>
    props.lines &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.lines};
      -webkit-box-orient: vertical;
    `}
`;

export default Paragraph;
