import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";

import Home from "./views/Home";
import Category from "./views/Category";
import Cart from "./views/Cart";
import Settings from "./views/Settings";
import Login from "./views/Login";
import Register from "./views/Register";
import ViewProduct from "./views/ViewProduct";
import Address from "./views/Address";
import Search from "./views/Search";

function PrivateRoute({ component: Component, ...args }) {
  return (
    <Route
      {...args}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/products/:productId" component={ViewProduct} />
      <Route path="/categories/:categoryId" component={Category} />
      <Route path="/search/:query" component={Search} />
      <PrivateRoute path="/cart" exact component={Cart} />
      <PrivateRoute path="/cart/address" component={Address} />
      <PrivateRoute path="/settings" component={Settings} />
      <Route path="/signup" component={Register} />
      <Route path="/signin" component={Login} />

      <Redirect path="*" to="/" />
    </Switch>
  );
}

export default Routes;
