import React from "react";
import styled from "styled-components";
import formatMoney from "../utils/formatMoney";
import { FaPlus } from "react-icons/fa";

function Component({ stores = [], onSelectProduct = () => {} }) {
  function handleSelect(item) {
    onSelectProduct(item);
  }

  return (
    <StoresList>
      {stores.map((store) => (
        <StoreContainer>
          <StoreDetails>
            <StorePhoto>
              <img src={store.avatar} />
            </StorePhoto>
            <StoreInfo>
              <h5>{store.name}</h5>
              <h6>{store.description}</h6>
              <p>
                {[store.street, store.number, store.city, store.state]
                  .filter(Boolean)
                  .join(", ")}
              </p>
              <p>
                Contato:{" "}
                {[store.contacts, store.email].filter(Boolean).join(", ")}
              </p>
              {store.cnpj && (
                <p>
                  CNPJ{" "}
                  {store.cnpj.replace(
                    /(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})\-?(\d{2})/,
                    "$1.$2.$3/$4-$5"
                  )}
                </p>
              )}
            </StoreInfo>
          </StoreDetails>
          <ProductsContainer>
            {store.products.map((product) => (
              <Card
                key={product.id}
                onClick={(event) => handleSelect(product, event)}
              >
                <Thumbnail src={product.thumbnail} />

                <Space />

                <Title>{product.name}</Title>
                <Price>{formatMoney(product.price)}</Price>

                <AddToCartButton>
                  <FaPlus />
                </AddToCartButton>
              </Card>
            ))}
          </ProductsContainer>
        </StoreContainer>
      ))}
    </StoresList>
  );
}

const StoresList = styled.div``;

const StoreContainer = styled.div``;

const StoreDetails = styled.div`
  position: relative;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  padding: 16px;
  box-sizing: border-box;
  margin: 8px;
  display: flex;
  overflow: hidden;
  gap: 16px;
`;

const StorePhoto = styled.div`
  width: 300px;
  max-width: 50%;

  img {
    width: 100%;
  }
`;

const StoreInfo = styled.div`
  flex: 1;

  h5 {
    line-height: 1.618;
    font-size: 22px;
    font-weight: 500;
    color: #3a3a3a;
    margin: 20px 0 0;
  }

  h6 {
    line-height: 1.618;
    font-size: 16px;
    font-weight: 500;
    color: #3a3a3a;
    margin: 0 0 20px;
  }

  p {
    line-height: 1.618;
    font-size: 14px;
    font-weight: 500;
    color: #3a3a3a;
    margin: 0;
  }
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

const Space = styled.div`
  flex: 1;
`;

const Card = styled.div`
  position: relative;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  padding: 16px;
  box-sizing: border-box;
  margin: 16px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
`;

const Title = styled.p`
  margin: 0;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: #3a3a3a;
  margin-right: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Price = styled.p`
  margin: 0;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  margin-right: 36px;
`;

const AddToCartButton = styled.button`
  position: absolute;
  border: none;
  cursor: pointer;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  font-size: 24px;
  width: 48px;
  height: 48px;
  border-radius: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Component;
